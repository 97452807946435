import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Meta from '../components/Meta'
import './ProductScreen.css'
import {
  listProductDetails,
  createProductReview,
  addProductReview,
} from '../actions/productActions'
import { PRODUCT_CREATE_REVIEW_RESET } from '../constants/productConstants'
import getFormatedPrice from '../utils/getFormatedPrice'
import { addToCart } from '../actions/cartActions'
import { useTranslation } from 'react-i18next'
// const getFormattedPrice = (price) => `$${price.toFixed(2)}`;
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ar from 'date-fns/locale/ar'
import it from 'date-fns/locale/it'
import fr from 'date-fns/locale/fr'
import en from 'date-fns/locale/en-US'
import Cookies from 'js-cookie'
import { currencies } from '../i18n'
import nl from 'date-fns/locale/nl'


function pad2(number) {
  return (number < 10 && number !== "00" ? '0' : '') + number

}
const ProductScreen = ({ history, match }) => {

  const [qty, setQty] = useState(1)
  const [discount, setDiscount] = useState()

  const [comment, setComment] = useState('')
  const [activeImage, setActiveImage] = useState(0)

  const dispatch = useDispatch()

  const discounts = [{ name: "0 à -4 an", discount: "1", }, { name: "4 à 12 ans", discount: "0.50" }, { name: "adult", discount: "0" }]
  const [isFirst, setIsFirst] = useState(false)
  const [firstQte, setFirstQte] = useState(1)
  const [isTwo, setIsTwo] = useState(false)
  const [twoQte, setTwoQte] = useState(1)
  const [isThree, setIsThree] = useState(true)
  const [threeQte, setThreeQte] = useState(1)

  const productDetails = useSelector((state) => state.productDetails)
  const { loading, error, product } = productDetails
  const [total, setTotal] = useState(0)

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const url = new URLSearchParams(window.location.search)

  const [dateBook, setDateBook] = useState(url.get('date') || '')
  const [dateBookR, setDateBookR] = useState(url.get('date') ? new Date(url.get('date')) : '')
  const currencInfo = localStorage.getItem("Currencies_difatours") || 'DHS';


  const [itemProd, setItemProd]=useState([])
  const productReviewCreate = useSelector((state) => state.productReviewCreate)
  const {
    success: successProductReview,
    loading: loadingProductReview,
    error: errorProductReview,
  } = productReviewCreate

  useEffect(() => {
    if (currencInfo === "DHS") {

      setTotal(product?.price)
    } else {
      setTotal(product?.price_euro)
    }

    
    // if(product.items !==undefined && product.items.length !== 0 ){
    //   console.log("itemProd");
    //   console.log(itemProd);
    //   setItemProd([product.items[0]._id]);
    //   console.log(itemProd);
    // }
  }, [product])


  useEffect(() => {
    if (successProductReview) {
      setRating(0)
      setComment('')
    }
    if (!product._id || product._id !== match.params.id) {
      dispatch(listProductDetails(match.params.id))
      dispatch({ type: PRODUCT_CREATE_REVIEW_RESET })

    }

  }, [dispatch, match, successProductReview])

  const addToCartHandler = async () => {
    console.log("jjjjjj");
    var queryTxt = "";
    await dispatch(addToCart(match.params.id, isFirst ? firstQte : 0, 1, isTwo ? twoQte : 0, 0.5, isThree ? threeQte : 0, 0, dateBook,itemProd)).then(() => {
      history.push(`/cart/`)
    })

    // if (discount) {
    // } else {
    //   history.push(`/cart/${match.params.id}?qty=${qty}&discount=${0}`)
    // }

  }




  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      createProductReview(match.params.id, {
        rating,
        comment,
      })
    )
  }

  const handleChangeDiscount = (e) => {
    // console.log(e.target.value)
    setDiscount(e.target.value)
    if (e.target.value) {
      setTotal(parseFloat(product.price) - parseFloat(e.target.value) * parseFloat(product.price))
    }
    else {
      setTotal(parseFloat(product.price))
    }

  }


  const goBack = () => {
    history.goBack()
  }

  const getTotalPrice = (price) => {


    var total = 0;
    if (isFirst) {
      console.log("first");
      total += (parseFloat(price) - (parseFloat(price) * 1)) * firstQte;
    }
    if (isTwo) {
      console.log("two");
      total += (parseFloat(price) - (parseFloat(price) * 0.5)) * twoQte;
    }
    if (isThree) {
      console.log("three");
      total += parseFloat(price) * threeQte;
    }

    return total.toFixed(2) + t(currencies.find(l => l.code === currencInfo).price);
  }


  const getTotalItemsPrice = (product) => {


    var total = 0;
    if(product.items !==undefined && product.items.length !== 0 ){
      if(itemProd?.length===0){
       console.log("nice");
        if (isFirst) {
          console.log("first");
          total += (parseFloat(currencInfo === "DHS"?product.items[0].price:product.items[0].price_euro) - (parseFloat(currencInfo === "DHS"?product.items[0]?.price:product.items[0]?.price_euro) * 1)) * firstQte;
        }
        if (isTwo) {
          console.log("two");
          total += (parseFloat(currencInfo === "DHS"?product.items[0].price:product.items[0].price_euro) - (parseFloat(currencInfo === "DHS"?product.items[0].price:product.items[0].price_euro) * 0.5)) * twoQte;
        }
        if (isThree) {
          console.log("three");
          console.log(product.items);
          total += parseFloat(currencInfo === "DHS"?product.items[0].price:product.items[0].price_euro) * threeQte;
        }
      }else{
        for (let index = 0; index < itemProd.length; index++) {
          const element = itemProd[index];

          for (let indexAll = 0; indexAll < product.items.length; indexAll++) {
            const elementAll = product.items[indexAll];
            if(elementAll._id===element){
              console.log("hhhh");
              console.log(elementAll);
              if (isFirst) {
                console.log("first");
                total += (parseFloat(currencInfo === "DHS"?elementAll.price:elementAll.price_euro) - (parseFloat(currencInfo === "DHS"?elementAll?.price:elementAll?.price_euro) * 1)) * firstQte;
              }
              if (isTwo) {
                console.log("two");
                total += (parseFloat(currencInfo === "DHS"?elementAll.price:elementAll.price_euro) - (parseFloat(currencInfo === "DHS"?elementAll.price:elementAll.price_euro) * 0.5)) * twoQte;
              }
              if (isThree) {
                console.log("three");
                total += parseFloat(currencInfo === "DHS"?elementAll.price:elementAll.price_euro) * threeQte;
              }
              break;
            }
            
          }

        }
      }
    }else{
      if (isFirst) {
        console.log("first");
        total += (parseFloat(currencInfo === "DHS"?product.price:product.price_euro) - (parseFloat(currencInfo === "DHS"?product.price:product.price_euro) * 1)) * firstQte;
      }
      if (isTwo) {
        console.log("two");
        total += (parseFloat(currencInfo === "DHS"?product.price:product.price_euro) - (parseFloat(currencInfo === "DHS"?product.price:product.price_euro) * 0.5)) * twoQte;
      }
      if (isThree) {
        console.log("three");
        total += parseFloat(currencInfo === "DHS"?product.price:product.price_euro) * threeQte;
      }
    }
      
    return total.toFixed(2) + t(currencies.find(l => l.code === currencInfo).price);
  }


  const checkHasCount = (count) => {
    var cnt = 0;
    if (isFirst) {
      cnt += parseInt(firstQte)
    }
    if (isTwo) {
      cnt += parseInt(twoQte)
    }
    if (isThree) {
      cnt += parseInt(threeQte)
    }

    if (count >= cnt) { return true; }
    else {
      return false;
    }
  }

  const isDateBetween = (dateToCheck, startDate, endDate) => {
    const dateToCheckObj = new Date(dateToCheck);
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    return dateToCheckObj >= startDateObj && dateToCheckObj <= endDateObj;
  };

  const [review, setReview] = useState('');
  const [rating, setRating] = useState(0);

  const handleInputChange = (e) => {
    setReview(e.target.value);
  };

  const handleRatingChange = (ratingValue) => {
    setRating(ratingValue);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here, you can save the review and rating data to your backend or do further processing
    dispatch(addProductReview(match.params.id, rating, review)).then(() => {
      dispatch(listProductDetails(match.params.id))
    })
    // Clear the input fields after submitting
    setReview('');
    setRating(0);
  };

  const { t } = useTranslation();
  const hundlChangeInputFilter = (e) => {
    setDateBook(e.toISOString().split('T')[0]);
    setDateBookR(e);
    // alert(e)
    // setDateBook(e.toISOString().split('T')[0]);
  }

  const getImageFirst = (images)=>{
      var check = false;
      var image = null;
      for (let index = 0; index < images.length; index++) {
          const element = images[index];
          if(element.isfirst){
              image=element;
              check=true;
          }
      }
      if(!check){
          image=images[0]
      }
      return image
  }

  const getImages = (images)=>{
    if(images)
    {if(images.length===1){
      return images;
    }else{
      var imageslist = [];
      for (let index = 0; index < images.length; index++) {
        const element = images[index];
        if(element._id!== getImageFirst(images)._id){
          imageslist.push(element);
        } 
      }
      return imageslist;
    }}else{
      return [];
    }
  }
  const galleryRef = useRef();

  const scrollLeft = () => {
    galleryRef.current.scrollLeft -= 112; // Adjust the scroll distance as needed
  };

  const scrollRight = () => {
    galleryRef.current.scrollLeft += 112; // Adjust the scroll distance as needed
  }
  return (
    <div className="pt-32 pb-20 xl:px-0 px-4 max-w-screen-xl mx-auto">
      <button className='my-4 ' onClick={goBack}>
        <div className="flex px-2">
          <svg xmlns="http://www.w3.org/2000/svg" className={Cookies.get("i18next") === 'ar' ? "h-6 w-6 rotate-180" : "h-6 w-6"} fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16l-4-4m0 0l4-4m-4 4h18" />
          </svg>
          <div className="pl-3">
            {t("back")}
          </div>
        </div>
      </button>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{t(error)}</Message>
      ) : (
        <>
          {/* <Meta title={product.name} /> */}
          <div className="md:flex rounded-md pt-6 gap-3  ">

            <div className='md:w-2/3 ' >
              <div className=' flex flex-col-reverse items-start justify-center  relative '>
                
                <div className="pt-4 relative w-full  overflow-hidden ">
                    <div className="flex items-center overflow-x-scroll" ref={galleryRef}>
                      {product?.images &&
                        getImages(product.images)?.map((img, id) => (
                          <div
                            key={id}
                            
                            style={{
                              backgroundImage: `url(${process.env.REACT_APP_ENV === "prod" ? 'https://api.diffatours.com' : 'http://127.0.0.1:8080'}${img.image})`,
                            }}
                            onClick={() => setActiveImage(id)}
                            className={`${
                              activeImage === id ? "opacity-100" : "opacity-30"
                            } bg-gray-200 w-28 h-16 md:w-40 md:h-24 aspect-video bg-cover bg-center bg-no-repeat rounded-md md:mt-0 mr-2 my-2`}
                          >
                            {/* <img src={img.image} className="object-contain " alt="" /> */}
                          </div>
                        ))}
                    </div>
                    <button
                      onClick={scrollLeft}
                      className="absolute left-0 top-1/2 transform -translate-y-1/2 px-2 py-1 bg-gray-300 text-gray-700 rounded-full"
                    >
                      ←
                    </button>
                    <button
                      onClick={scrollRight}
                      className="absolute right-0 top-1/2 transform -translate-y-1/2 px-2 py-1 bg-gray-300 text-gray-700 rounded-full"
                    >
                      →
                    </button>
                    </div>
                  
                <div className='flex-grow w-full rounded-md relative'>
                  {/* principal image */}
                  <img ch="kk" className='rounded-md max-h-[520px] object-contain mx-auto w-full' src={`${process.env.REACT_APP_ENV === "prod" ? 'https://api.diffatours.com' : 'http://127.0.0.1:8080'}${product?.images?.length > 0 && getImages(product.images)[activeImage].image}`} alt={product?.images?.length > 0 && product?.images[activeImage]?.name?product?.images[activeImage]?.name:"diffatours"} />
                  {/* next */}
                  <button className='absolute top-1/2 bg-white shadow-md -right-3 md:right-0 p-2 disabled:bg-opacity-20  rounded-full ' disabled={activeImage >=  getImages(product.images)?.length - 1} onClick={() => activeImage <  getImages(product.images)?.length - 1 ? setActiveImage(activeImage + 1) : setActiveImage( getImages(product.images).length - 1)} >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                    </svg>
                  </button>
                  {/* prev */}
                  <button className='absolute top-1/2 bg-white shadow-md -left-3 md:left-0 p-2 rounded-full disabled:bg-opacity-20 ' disabled={activeImage <= 0} onClick={() => activeImage > 0 ? setActiveImage(activeImage - 1) : setActiveImage(0)} >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                    </svg>
                  </button>
                  {/*  */}
                </div>
              </div>
              

            </div>
            <div className="md:w-1/3 mt-4 md:mt-0 md:mx-2 bg-secondary bg-opacity-20 p-4 rounded-xl">

              <h1 className="text-2xl uppercase  " dir='ltr'>
              {
                Cookies.get("i18next") === "ar" && product.namear ?
                  product.namear
                  : Cookies.get("i18next") === "fr" && product.namefr ?
                    product.namefr
                    : Cookies.get("i18next") === "it" && product.nameit ?
                      product.nameit
                      : Cookies.get("i18next") === "nl" && product.namenl ?
                        product.namenl
                        :
                        product.name}
              </h1>
              <div className="flex items-center" >
                <div >
                  <span
                    className={`text-xl cursor-pointer text-gray-300`} >
                    ({parseFloat(product.rating).toFixed(1)})
                  </span>
                  {[1, 2, 3, 4, 5].map((star) => (
                    <span
                      key={star}
                      className={`text-xl cursor-pointer ${star <= product.rating ? 'text-yellow-400' : 'text-gray-300'
                        }`}
                    >
                      ★
                    </span>
                  ))}
                </div>
              </div>

              {/* <h2 className="text-xl py-4">{getFormatedPrice(parseFloat(total))}  </h2> */}
              {
                product && 
                <h2 className="text-xl mt-4 mb-2">{getTotalItemsPrice(product)}  </h2>
              }
              {
                product.items?.length!==0?
                <div className='mtb-3'>{t("you_can_select_more_items")}</div>:null
              }
              <div>
                {
                  product.items?.map((item)=>
                    <div >
                      <input type="checkbox" name={item._id} className='mx-2' id={item._id} value={item._id} onChange={(e) => {
                        if (itemProd.includes(item._id)) {
                          console.log("in ");
                          const updatedItemProd = itemProd.filter((itm) => itm !== item._id);
                          console.log(updatedItemProd);
                          setItemProd(updatedItemProd);
                        } else {
                          console.log("not in");
                          setItemProd([...itemProd, item._id]);
                        }
                        console.log(itemProd);
                      }} checked={(itemProd.length===0 && product.items[0]._id===item._id) || itemProd.includes(item._id)} />
                      <label for={item._id} className='text-sm font-bold'>
                        {
                          Cookies.get("i18next") === "ar" && item.namear ?
                            item.namear
                              : Cookies.get("i18next") === "fr" && item.namefr ?
                              item.namefr
                                : Cookies.get("i18next") === "it" && item.nameit ?
                                item.nameit
                                  : Cookies.get("i18next") === "nl" && item.namenl ?
                                  item.namenl
                                    : item.name
                        } ({currencInfo === "DHS" ? item.price : item.price_euro} {t(currencies.find(l => l.code === currencInfo).price)})
                      </label>
                    </div>
                  )
                }
              </div>


              <div className='pt-2 px-1'>
                <p className='opacity-70 uppercase'>{t("number_of_people")}</p>
                {/* 0 à -4 an */}
                <div key="first" className='p-1'>
                  <input type="checkbox" name="discount" className='mx-2' id="first" value={1} onChange={(e) => setIsFirst(!isFirst)} defaultChecked={isFirst} />
                  <label for="first">{t("0_to_4_years")}</label>
                  {
                    isFirst ?
                      <div className="p-1">
                        <label htmlFor="" className="opacity-70">{t("num_people_0_to_4_years")}</label>
                        <select dir='ltr' value={firstQte} onChange={(e) => setFirstQte(e.target.value)} className="mt-2 w-full p-2 px-6 select border-none outline-none rounded-full" >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                      </div> : null
                  }
                </div>
                {/* 0 à -4 an */}
                <div key="two" className='p-1'>
                  <input type="checkbox" name="discount" className='mx-2' id="two" value={0.5} onChange={(e) => setIsTwo(!isTwo)} defaultChecked={isTwo} />
                  <label for="two">{t("4_to_12_years")}</label>
                  {
                    isTwo ?
                      <div className="p-1">
                        <label htmlFor="" className="opacity-70">{t("num_people_4_to_12_years")}</label>
                        <select dir='ltr' value={twoQte} onChange={(e) => setTwoQte(e.target.value)} className="mt-2 w-full p-2 px-6 select border-none outline-none rounded-full" >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                      </div> : null
                  }
                </div>
                {/* adult */}
                <div key="three" className='p-1'>
                  <input type="checkbox" name="discount" className='mx-2' id="three" value={0.5} onChange={(e) => setIsThree(!isThree)} defaultChecked={isThree} />
                  <label for="three">{t("adult")}</label>
                  {
                    isThree ?
                      <div className="p-1">
                        <label htmlFor="" className="opacity-70">{t("num_people_adults")}</label>
                        <select dir='ltr' value={threeQte} onChange={(e) => setThreeQte(e.target.value)} className="mt-2 w-full p-2  px-6 select border-none outline-none rounded-full" >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                      </div> : null
                  }
                </div>

                <div className='p-1'>
                  <p className='opacity-70 uppercase'>{t("date_of_booking")}</p>
                  <DatePicker
                    className='m-1 mt-2 w-full p-2 px-6  border-none outline-none rounded-full ' dir="ltr"
                    selected={dateBookR}
                    showIcon
                    onChange={(date) => hundlChangeInputFilter(date)}
                    dateFormat="dd/MM/yyyy" // Customize the date format as needed
                    maxDate={new Date(product.end_date)}
                    minDate={new Date(product.start_date)}
                    placeholderText={t("date_of_booking")}
                    locale={Cookies.get("i18next") === "ar" ? ar : Cookies.get("i18next") === "fr" ? fr : Cookies.get("i18next") === "it" ? it :  Cookies.get("i18next") === "nl" ? nl : en} // Set the Arabic locale
                  />
                  {/* <input min={product.start_date} max={product.end_date} value={dateBook} onChange={(e) => setDateBook(e.target.value)} className='m-1 mt-2 w-full p-2 pl-6  border-none outline-none rounded-full' type='date' /> */}
                </div>
              </div>


              <div>
              </div>


              <div className="pt-4 ">
                <button onClick={addToCartHandler} className={`w-full uppercase overflow-hidden box-border ${product.countInStock <= 0 || !checkHasCount(product.countInStock) || (!isFirst && !isTwo && !isThree) || !isDateBetween(dateBook, product.start_date, product.end_date) ? 'bg-gray-500' : 'bg-black '}  p-2 text-white fade-in ${product.countInStock <= 0 || !checkHasCount(product.countInStock) || (!isFirst && !isTwo && !isThree) || !isDateBetween(dateBook, product.start_date, product.end_date) ? '' : 'hover:scale-[1.01]'}  rounded-full `} disabled={product.countInStock <= 0 || !checkHasCount(product.countInStock) || (!isFirst && !isTwo && !isThree) || !isDateBetween(dateBook, product.start_date, product.end_date)}>{product.countInStock <= 0 || !checkHasCount(product.countInStock) ? t("sold_out") : t("add_to_cart")}</button>
              </div>

              <div className='pt-4'>
                <div className='pb-2'>
                  {t("share_with_friends")}
                </div>

                <div className='flex items-center'>
                  {/* window.location.href */}
                  {/* <a className='px-2' href={`https://www.facebook.com/sharer/sharer.php?u=${`https://diffatours.aynnaka.app/product/${product.id}/${product.slug}/`}`}> */}
                  <a className='px-2' href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" className='hover:fill-primary' viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z" /><path d="M13 19.938A8.001 8.001 0 0 0 12 4a8 8 0 0 0-1 15.938V14H9v-2h2v-1.654c0-1.337.14-1.822.4-2.311A2.726 2.726 0 0 1 12.536 6.9c.382-.205.857-.328 1.687-.381.329-.021.755.005 1.278.08v1.9H15c-.917 0-1.296.043-1.522.164a.727.727 0 0 0-.314.314c-.12.226-.164.45-.164 1.368V12h2.5l-.5 2h-2v5.938zM12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10z" /></svg>
                  </a>
                  {/* <a className='px-2' href={`https://twitter.com/intent/tweet?url=${`https://diffatours.aynnaka.app/product/${product.id}/${product.slug}/`}`}> */}
                  <a className='px-2' href={`https://twitter.com/intent/tweet?url=${window.location.href}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" className='hover:fill-primary' viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z" /><path d="M15.3 5.55a2.9 2.9 0 0 0-2.9 2.847l-.028 1.575a.6.6 0 0 1-.68.583l-1.561-.212c-2.054-.28-4.022-1.226-5.91-2.799-.598 3.31.57 5.603 3.383 7.372l1.747 1.098a.6.6 0 0 1 .034.993L7.793 18.17c.947.059 1.846.017 2.592-.131 4.718-.942 7.855-4.492 7.855-10.348 0-.478-1.012-2.141-2.94-2.141zm-4.9 2.81a4.9 4.9 0 0 1 8.385-3.355c.711-.005 1.316.175 2.669-.645-.335 1.64-.5 2.352-1.214 3.331 0 7.642-4.697 11.358-9.463 12.309-3.268.652-8.02-.419-9.382-1.841.694-.054 3.514-.357 5.144-1.55C5.16 15.7-.329 12.47 3.278 3.786c1.693 1.977 3.41 3.323 5.15 4.037 1.158.475 1.442.465 1.973.538z" /></svg>
                  </a>

                  {/* <a className='px-2' href={`https://pinterest.com/pin/create/button/?url=${`https://diffatours.aynnaka.app/product/${product.id}/${product.slug}/`}`}> */}
                  <a className='px-2' href={`https://pinterest.com/pin/create/button/?url=${window.location.href}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" className='hover:fill-primary' viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z" /><path d="M8.49 19.191c.024-.336.072-.671.144-1.001.063-.295.254-1.13.534-2.34l.007-.03.387-1.668c.079-.34.14-.604.181-.692a3.46 3.46 0 0 1-.284-1.423c0-1.337.756-2.373 1.736-2.373.36-.006.704.15.942.426.238.275.348.644.302.996 0 .453-.085.798-.453 2.035-.071.238-.12.404-.166.571-.051.188-.095.358-.132.522-.096.386-.008.797.237 1.106a1.2 1.2 0 0 0 1.006.456c1.492 0 2.6-1.985 2.6-4.548 0-1.97-1.29-3.274-3.432-3.274A3.878 3.878 0 0 0 9.2 9.1a4.13 4.13 0 0 0-1.195 2.961 2.553 2.553 0 0 0 .512 1.644c.181.14.25.383.175.59-.041.168-.14.552-.176.68a.41.41 0 0 1-.216.297.388.388 0 0 1-.355.002c-1.16-.479-1.796-1.778-1.796-3.44 0-2.985 2.491-5.584 6.192-5.584 3.135 0 5.481 2.329 5.481 5.14 0 3.532-1.932 6.104-4.69 6.104a2.508 2.508 0 0 1-2.046-.959l-.043.177-.207.852-.002.007c-.146.6-.248 1.017-.288 1.174-.106.355-.24.703-.4 1.04a8 8 0 1 0-1.656-.593zM12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10z" /></svg>
                  </a>
                  {/* <a className='px-2' href={`https://www.linkedin.com/shareArticle?url=${`https://diffatours.aynnaka.app/product/${product.id}/${product.slug}/`}`}> */}
                  <a className='px-2' href={`https://www.linkedin.com/shareArticle?url=${window.location.href}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" className='hover:fill-primary' viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z" /><path d="M12 9.55C12.917 8.613 14.111 8 15.5 8a5.5 5.5 0 0 1 5.5 5.5V21h-2v-7.5a3.5 3.5 0 0 0-7 0V21h-2V8.5h2v1.05zM5 6.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm-1 2h2V21H4V8.5z" /></svg>
                  </a>
                </div>
              </div>

              {/*  */}
              {/* <div className='pt-4 '>
                <div className="container mx-auto mt-2">
                  <h1 className="text-sm">Add Your Review</h1>
                  <div className="flex items-center">
                    <div>
                      {[1, 2, 3, 4, 5].map((star) => (
                        <span
                          key={star}
                          className={`text-2xl cursor-pointer ${star <= rating ? 'text-yellow-400' : 'text-gray-300'
                            }`}
                          onMouseEnter={() => handleRatingChange(star)}
                          // onMouseLeave={() => handleRatingChange(0)}
                          onClick={() => handleRatingChange(star)}
                        >
                          ★
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="">
                    <label>Review:</label>
                    <textarea
                      className="w-full border rounded p-2"
                      rows="2"
                      value={review}
                      onChange={handleInputChange}
                    />
                  </div>
                  <button
                    type="submit"
                    className="bg-blue-300 text-white rounded-full px-3 py-1 w-full"
                    disabled={!rating || !review.trim()}
                    onClick={handleSubmit}
                  >
                    Save Review
                  </button>
                </div>
              </div> */}

            </div>

          </div>

          <div className='md:flex pt-6'>

            <div className='md:w-2/3'>
              <div className='flex items-center '>
                <h1 className=' py-3 italic flex-none pr-4'>{t("description")} </h1>
                <div className='bg-gray-300 h-[1px] rounded-sm flex-grow '></div>
              </div>

              <div className='p-2 ' dir={Cookies.get("i18next") === "ar" && product.description_ar ? 'rtl' : 'ltr'}>
                <p className="pt-3 whitespace-pre-line" >
                  {
                    Cookies.get("i18next") === "ar" && product.description_ar ?
                      product.description_ar
                      : Cookies.get("i18next") === "fr" && product.description_fr ?
                        product.description_fr
                        : Cookies.get("i18next") === "it" && product.description_it ?
                          product.description_it
                          : Cookies.get("i18next") === "nl" && product.description_nl ?
                            product.description_nl
                            :
                            product.description}
                </p>
              </div>

            </div>


          </div>

        </>
      )}
    </div>
  )
}

export default ProductScreen


{/* <div className="">
<div className="p-3">
  <img src={product.image} alt={product.name}  />
</div>
<div >
  <div >
    <div>
      <h3>{product.name}</h3>
    </div>
    <div>
      <Rating
        value={product.rating}
        text={`${product.numReviews} reviews`}
      />
    </div>
    <div>Price: ${product.price}</div>
                <div>
                  Description: {product.description}
                </div>
              </div>
            </div>

            <div className="">
              <div>
                <div >
                  <div>
                    <div className="flex">
                      <div>Price:</div>
                      <div>
                        <strong>${product.price}</strong>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="flex">
                      <div>Status:</div>
                      <div>
                        {product.countInStock > 0 ? 'In Stock' : 'Out Of Stock'}
                      </div>
                    </div>
                  </div>

                  {product.countInStock > 0 && (
                    <div>
                      <div className="flex">
                        <div>Qty</div>
                        <div>
                          <select
                            as='select'
                            value={qty}
                            onChange={(e) => setQty(e.target.value)}
                          >
                            {[...Array(product.countInStock).keys()].map(
                              (x) => (
                                <option key={x + 1} value={x + 1}>
                                  {x + 1}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </div>
                    </div>
                  )}

                  <div>
                    <button
                      onClick={addToCartHandler}
                      className='bg-primary px-3 mt-3 py-1'
                      type='button'
                      disabled={product.countInStock === 0}
                    >
                      Add To Cart
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div >
              <h2>Reviews</h2>
              {product.reviews.length === 0 && <Message>No Reviews</Message>}
              <div >
                {product.reviews.map((review) => (
                  <div key={review._id}>
                    <strong>{review.name}</strong>
                    <Rating value={review.rating} />
                    <p>{review.createdAt.substring(0, 10)}</p>
                    <p>{review.comment}</p>
                  </div>
                ))}
                <div>
                  <h2>Write a Customer Review</h2>
                  {successProductReview && (
                    <Message variant='success'>
                      Review submitted successfully
                    </Message>
                  )}
                  {loadingProductReview && <Loader />}
                  {errorProductReview && (
                    <Message variant='danger'>{errorProductReview}</Message>
                  )}
                  {userInfo ? (
                    <form onSubmit={submitHandler}>
                      <div controlId='rating'>
                        <label>Rating</label>
                        <select
                          value={rating}
                          onChange={(e) => setRating(e.target.value)}
                        >
                          <option value=''>Select...</option>
                          <option value='1'>1 - Poor</option>
                          <option value='2'>2 - Fair</option>
                          <option value='3'>3 - Good</option>
                          <option value='4'>4 - Very Good</option>
                          <option value='5'>5 - Excellent</option>
                        </select>
                      </div>
                      <div controlId='comment'>
                        <label>Comment</label>
                        <input
                          type="text"
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        />
                      </div>
                      <button
                        disabled={loadingProductReview}
                        type='submit'
                        variant='primary'
                      >
                        Submit
                      </button>
                    </form>
                  ) : (
                    <Message>
                      Please <Link className="text-blue-900" to='/login'>sign in</Link> to write a review{' '}
                    </Message>
                  )}
                </div>
              </div>
            </div>
          </div> */}
