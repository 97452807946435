import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SideBar from '../../components/SideBar'
import { createCategory, createTypeCategory, deleteCategory, deleteSubCategory, deleteTypeCategory, listCategory, updateCategory } from '../../actions/categoryActions'
import Axios from 'axios'
import useForm from '../../hooks/useFrom'
import { validate } from '../../validation/CategoryFormValidationsRules'
import toFormData from '../../utils/toFormData'
import Layout from './Layout'
import { toast } from 'react-toastify'

if (process.env.REACT_APP_ENV === "prod") {
    Axios.defaults.baseURL = 'https://api.diffatours.com'
} else {
    Axios.defaults.baseURL = 'http://127.0.0.1:8080'
    // axios.defaults.baseURL = 'http://127.0.0.1:8000'
}

const AddNewType = () => {

    // type/add/
    const [name, setName] = useState("")
    const dispatch = useDispatch()

    const handleAddNewType = () => {
        dispatch(createTypeCategory({ name }))
    }

    const [filters, setFilters] = useState([])


    const handleDeleteType = (event) => {

        const removeId = event.target.dataset.remove

        if (removeId) {
            dispatch(deleteTypeCategory(removeId))
        }
    }

    useEffect(() => {
        Axios.get(`/api/categories/1/ticket-types/`).then(res => {
            setFilters(res.data.tickettypes)
        }).catch(err => {
            setFilters([])
        })

    }, [])



    return (
        <div>
            <h1 className='font-bold'>Filters</h1>

            <div className='flex flex-wrap py-2'>
                {filters.map(item =>
                    <div key={item._id} className="relative">
                        <form data-remove={item._id} onSubmit={handleDeleteType} >
                            <button className='absolute text-xs w-6 h-6 border  -top-3 rounded-full bg-white text-gray-600 right-2'>
                                X
                            </button>
                        </form>
                        <div className={`py-2 px-4 mr-4 mb-4 border rounded-md`}  >  {item.name}    </div>
                        {/* <input type="text" value={"hi"} className={`py-2 px-4 mr-4 mb-4 border rounded-md w-auto ${hideFilter ? "visible":"hidden"}`} /> */}

                    </div>
                )}


            </div>
            <form onSubmit={handleAddNewType} >
                <div className='border flex w-1/2'>

                    <input placeholder='Lundi' className='p-2 outline-none bg-transparent w-full' type="text" value={name} onChange={(e) => setName(e.target.value)} />
                    <button className='whitespace-nowrap px-4 bg-black text-white'>
                        OK
                    </button>
                </div>
            </form>


        </div>
    )
}

const CategoryItem = ({ category }) => {
    const dispatch = useDispatch()

    const [name, setName] = useState(category.name)
    const [nameFr, setNameFr] = useState(category.namefr)
    const [nameAr, setNameAr] = useState(category.namear)
    const [nameIt, setNameIt] = useState(category.nameit)
    const [nameLn, setNameLn] = useState(category.nameln)
    const [imageCategory, setImageCategory] = useState("")

    const [openEditCategory, setOpenEditCategory] = useState(false)

    const [update,setUpdate] = useState(false)
    const [isDelete,setIsDelete] = useState(false)

    const handleDeleteCategory = (id) =>async (ev) => {
        ev.preventDefault()
        setIsDelete(true);
       await dispatch(deleteCategory(id)).then(()=>{
            setIsDelete(false);
            // toast.success('🦄 Wow so easy!', {
            //     position: "top-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "light",
            //     });
       })
    }

    const handleEditCategory = (id) => (ev) => {
        ev.preventDefault()

        setUpdate(true);

        imageCategory ?
            dispatch(updateCategory(id, { name, image: imageCategory,namear:nameAr,namefr:nameFr,nameit:nameIt,nameln:nameLn, })) :
            dispatch(updateCategory(id, { name, image: "",namear:nameAr,namefr:nameFr,nameit:nameIt,nameln:nameLn, }))
        setUpdate(false);
    }

    return (
        <>
            <div className='flex flex-col'>
                <div className=''>
                    <div className="w-32 shadow-sm bg-white rounded-md border p-2 h-32">
                        <img alt='difatours' className='h-full w-full rounded-md object-cover mr-3' src={`${process.env.REACT_APP_ENV === "prod" ? 'https://api.diffatours.com' : 'http://127.0.0.1:8080'}${category.image}`} />
                    </div>
                </div>
                <div className='mt-1'>
                    <div className='font-bold'>
                        {category.name}
                    </div>
                    <div className="flex gap-x-3 text-xs pt-1 font-thin">
                        <button onClick={handleDeleteCategory(category._id)} className=' inline-flex'>
                            {
                                   isDelete?
                                    <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-grey-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>   :null
                                } supprimer
                        </button>
                        <button className='text-grey-300' onClick={() => setOpenEditCategory(true)}>
                            modifier
                        </button>
                    </div>
                </div>




            </div >

            {/* <div className={`p-4 ${openEditCategory ? "" : "hidden"} border w-[300px] md:w-[360px] absolute left-1/2 -translate-x-1/2 z-[50] bg-white shadow-md top-3/4 transform -translate-y-1/2`}> */}
                <div className={`${openEditCategory ? "" : "hidden"} fixed  top-0 left-0 w-full h-full flex items-center justify-center`}>
                    <div className='py-4 px-5 max-w-md bg-white shadow-lg  border '>
                        <div className='flex items-center justify-between'>
                            <div>
                                Modifier <b>{category.name}</b>
                            </div>
                            <button className='text-red-600' onClick={() => setOpenEditCategory(false)}>Close</button>
                        </div>

                        <div className='pt-2'>
                            <form onSubmit={handleEditCategory(category._id)}>
                                <div className='border my-2 flex items-center '>

                                    <img src={`${process.env.REACT_APP_ENV === "prod" ? 'https://api.diffatours.com' : 'http://127.0.0.1:8080'}${category.image}`} className="w-10" alt="" />
                                    <input className='p-2 outline-none bg-transparent w-full' type="file" placeholder='image' onChange={(e) => setImageCategory(e.target.files[0])} />
                                </div>
                                
                                <p className="help text-xs mt-2">Nom du Catégorie (en): <span className='text-red-500'>*</span></p>
                                <div className='border mb-2 '>
                                    <input className='p-2 outline-none bg-transparent w-full' type="text" placeholder='Nom du Catégorie (en)' value={name} onChange={(e) => setName(e.target.value)} />
                                </div>
                                {/*  */}
                                <p className="help text-xs mt-2">Nom du Catégorie (fr): <span className='text-red-500'>*</span></p>
                                <div className='border mb-2 '>
                                    <input className='p-2 outline-none bg-transparent w-full' type="text" placeholder='Nom du Catégorie (fr)' value={nameFr} onChange={(e) => setNameFr(e.target.value)} />
                                </div>
                                {/*  */}
                                <p className="help text-xs mt-2 hidden">Nom du Catégorie (ar): <span className='text-red-500'>*</span></p>
                                <div className='border mb-2 hidden'>
                                    <input className='p-2 outline-none bg-transparent w-full' type="text" placeholder='Nom du Catégorie (ar)' value={nameAr} onChange={(e) => setNameAr(e.target.value)} />
                                </div>
                                {/*  */}
                                <p className="help text-xs mt-2">Nom du Catégorie (it): <span className='text-red-500'>*</span></p>
                                <div className='border mb-2 '>
                                    <input className='p-2 outline-none bg-transparent w-full' type="text" placeholder='Nom du Catégorie (it)' value={nameIt} onChange={(e) => setNameIt(e.target.value)} />
                                </div>
                                {/*  */}
                                <p className="help text-xs mt-2">Nom du Catégorie (nl): <span className='text-red-500'>*</span></p>
                                <div className='border mb-2 '>
                                    <input className='p-2 outline-none bg-transparent w-full' type="text" placeholder='Nom du Catégorie (nl)' value={nameLn} onChange={(e) => setNameLn(e.target.value)} />
                                </div>

                                <button className='whitespace-nowrap p-2 bg-black text-white inline-flex' disabled={update}>
                                   {
                                        update?
                                            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>   :null
                                    } Modifier 
                                </button>
                            </form>

                        </div>
                    </div>
                </div>
            {/* </div> */}

            {/* <ul>
                {category?.subcategories?.map(subcat => <li className='flex justify-between items-center'>
                    <div>
                        {subcat.name}
                    </div>
                    <button className='text-blue-600 border-b text-sm border-blue-600' onClick={handleDeleteSubCategory(subcat._id)}>
                        Delete
                    </button>
                </li>)}
            </ul> */}
        </>
    )
}

const ConfigScreen = ({ history }) => {
    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const [openAddCategory, setOpenAddCategory] = useState(false)

    const categoryCreate = useSelector((state) => state.categoryCreate)

    const { loading: loadingCreateCategory, error: errorCreateCategory, success, category } = categoryCreate

    const categoryList = useSelector((state) => state.categoryList)

    const dispatch = useDispatch()

    const { loading, error, categories } = categoryList

    useEffect(() => {
        dispatch(listCategory())
    }, [])

    const handleAddCategory = async () => {
        const data = toFormData(values)
        setIsAdd(true);
        await dispatch(createCategory(data)).then(() => {
            dispatch(listCategory())
            // window.location.reload();
            setIsAdd(false);
        })

    }

    const {
        values,
        errors,
        handleChange,
        handleImageChange,
        handleSubmit,
    } = useForm(handleAddCategory, validate)


    useEffect(() => {
        if (userInfo && userInfo.isAdmin) {
            if (success) {
                setOpenAddCategory(false)
            }
        } else {
            history.push('/login')
        }

    }, [success, history, userInfo])

    const [isShow, setIsShow] = useState(false);
    const [isAdd, setIsAdd] = useState(false);





    return (
        <>
            <Layout>

                <div className="px-2 relative w-full">
                    <h1 className='text-xl pb-1 '>Configuration </h1>


                    <hr />
                    <div className='pt-3'>
                        {/* <div className='flex justify-between items-center'>
                            <h2 className='font-semibold pb-2'>Catégories</h2>
                            <button onClick={() => setOpenAddCategory(true)} className={`border p-2 `}>
                                Ajouter Catégorie
                            </button>
                        </div> */}

                        <div className="pb-4">
                            <div className='flex flex-wrap gap-3'>
                                {categories?.map(cat => <CategoryItem category={cat} key={cat._id} />)}

                                <button
                                    onClick={() => setOpenAddCategory(true)}
                                    className="h-32 w-32 flex items-center justify-center bg-white rounded-md"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M12 4.5v15m7.5-7.5h-15"
                                        />
                                    </svg>
                                </button>
                            </div>

                        </div>



                        {/* <div className={`p-4 ${openAddCategory ? "" : "hidden"} absolute left-1/2 -translate-x-1/2 z-50 bg-white shadow-md top-1/2 transform -translate-y-1/2`}> */}
                        <div className={`${openAddCategory ? "" : "hidden"} fixed  top-0 left-0 w-full h-full flex items-center justify-center`}>
                            <div className='py-4 px-5 max-w-md bg-white shadow-lg  border'>

                                <div className='flex items-center justify-between'>
                                    <div>
                                        Nouvelle catégorie
                                    </div>
                                    <button className='text-red-600' onClick={() => setOpenAddCategory(false)}>Close</button>
                                </div>

                                <div className='pt-2'>
                                    <form onSubmit={handleSubmit} >
                                        <div className={` border cursor-pointer  ${errors.image ? "border-red-600" : ""}  ${!values.image ? "border-dashed" : "border-green-400"} rounded-md my-2"`}>
                                            <label htmlFor="image">
                                                <input id="image" name="image" type="file" onChange={handleImageChange} className="p-2 w-full bg-transparent outline-none" placeholder="Image" />
                                            </label>
                                        </div>
                                        <span className='text-xs' style={{ color: "red" }}>{errors.image}</span>

                                        <p className="help text-xs mt-2">Nom du Catégorie (en): <span className='text-red-500'>*</span></p>
                                        <div className={` ${errors.name ? "border-red-500" : ""} border rounded-md mb-2`}>
                                            <input name='name' value={values.name || ''} onChange={handleChange} className="p-2 w-full bg-transparent outline-none" type="text" placeholder="Nom du Catégorie (en)" />
                                        </div>
                                        {errors.name && (
                                            <p className="help text-red-500 text-xs">{errors.name}</p>
                                        )}
                                        {/*  */}
                                        <p className="help text-xs mt-2 hidden">Nom du Catégorie (ar): <span className='text-red-500'>*</span></p>
                                        <div className={` ${errors.namear ? "border-red-500" : ""} border rounded-md mb-2 hidden`}>
                                            <input name='namear' value={values.namear || ''} onChange={handleChange} className="p-2 w-full bg-transparent outline-none" type="text" placeholder="Nom du Catégorie (ar)" />
                                        </div>
                                        {errors.namear && (
                                            <p className="help text-red-500 text-xs hidden">{errors.namear}</p>
                                        )}
                                        {/*  */}
                                        <p className="help text-xs mt-2">Nom du Catégorie (fr): <span className='text-red-500'>*</span></p>
                                        <div className={` ${errors.namefr ? "border-red-500" : ""} border rounded-md mb-2`}>
                                            <input name='namefr' value={values.namefr || ''} onChange={handleChange} className="p-2 w-full bg-transparent outline-none" type="text" placeholder="Nom du Catégorie (fr)" />
                                        </div>
                                        {errors.namefr && (
                                            <p className="help text-red-500 text-xs">{errors.namefr}</p>
                                        )}
                                        {/*  */}
                                        <p className="help text-xs mt-2">Nom du Catégorie (it): <span className='text-red-500'>*</span></p>
                                        <div className={` ${errors.nameit ? "border-red-500" : ""} border rounded-md mb-2`}>
                                            <input name='nameit' value={values.nameit || ''} onChange={handleChange} className="p-2 w-full bg-transparent outline-none" type="text" placeholder="Nom du Catégorie (it)" />
                                        </div>
                                        {errors.nameit && (
                                            <p className="help text-red-500 text-xs">{errors.nameit}</p>
                                        )}
                                        {/*  */}
                                        <p className="help text-xs mt-2">Nom du Catégorie (nl): <span className='text-red-500'>*</span></p>
                                        <div className={` ${errors.nameln ? "border-red-500" : ""} border rounded-md mb-2`}>
                                            <input name='nameln' value={values.nameln || ''} onChange={handleChange} className="p-2 w-full bg-transparent outline-none" type="text" placeholder="Nom du Catégorie (nl)" />
                                        </div>
                                        {errors.nameln && (
                                            <p className="help text-red-500 text-xs">{errors.nameln}</p>
                                        )}

                                        <button className='whitespace-nowrap p-2 mt-3 bg-black text-white inline-flex'>
                                        {
                                        isAdd?
                                            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>   :null
                                        } Ajouter
                                        </button>
                                    </form>

                                </div>
                            </div>
                        </div>

                        {/* <AddNewType /> */}



                    </div>

                </div>

            </Layout>
        </>
    )
}

export default ConfigScreen
